import dayjs from "dayjs";

export default function useTimer() {
  // Variables
  const startTime = ref(dayjs());
  const isRunning = ref(false);
  const seconds = ref(0);
  const formatedTime = ref("");

  const timer = ref<ReturnType<typeof setInterval> | null>(null);

  // Handle timer
  const handleTimer = () => {
    const now = dayjs();
    seconds.value = now.diff(startTime.value, "second");
    formatedTime.value = dayjs(seconds.value * 1000).format("mm:ss");

    setTimeout(() => {
      if (isRunning.value) {
        handleTimer();
      }
    }, 500);
  };

  const destroyTimer = () => {
    if (timer.value) {
      clearTimeout(timer.value);
    }
  };

  const resume = () => {
    startTime.value = dayjs().subtract(seconds.value, "second");
    isRunning.value = true;
    handleTimer();
  };

  onUnmounted(() => {
    destroyTimer();
  });

  // Methods
  const reset = () => {
    destroyTimer();
    startTime.value = dayjs();
    seconds.value = 0;
    formatedTime.value = "";
  };

  const stop = () => {
    destroyTimer();
    isRunning.value = false;
  };

  const start = () => {
    startTime.value = dayjs();
    isRunning.value = true;

    console.log("starting time");

    handleTimer();
  };

  return {
    formatedTime,
    seconds,
    resume,
    reset,
    stop,
    start,
  };
}
