import { toast } from "~/components/toast";
import { sendPlausibleEvent } from "~/libs/plausible";

export type AnswerLetters = "A" | "B" | "C" | "D" | "E";
const timer = useTimer();

export default function useBlockHandler(subjectId: string, competenceId?: string) {
  // Composables
  const blockLoader = useBlockLoader(subjectId, competenceId);
  const blockStore = useBlockStore();
  const authStore = useAuthStore();

  // Store state
  const {
    isLoading,
    questionNumber,
    stepNumber,
    scoreParams,
    questions,
    currentQuestion,
    selectedAnswer,
    isCelebrationOpen,
    answerStatus,
  } = storeToRefs(blockStore);

  // Methods
  const startNewBlock = async () => {
    blockStore.$reset();

    const blockData = await blockLoader.loadNewBlock();

    if (!blockData) {
      return;
    }

    const { questions, block } = blockData;

    if (!questions) {
      toast.error(
        "Erro ao carregar questões. Tente novamente. Se o problema persistir contate o suporte."
      );
      return;
    }

    const mmrs = questions.map((q) => q.mmr);

    console.log("mmrs", mmrs, block);

    blockStore.$patch({
      isLoading: false,
      questions,
    });

    blockStore.$patch((state) => {
      state.scoreParams.corrects = block.corrects;
      state.scoreParams.gradeChange = block.gradeChange;
      state.scoreParams.time = block.time;
    });

    timer.start();

    sendPlausibleEvent("Questões - Bloco iniciado");
  };

  const finishBlock = () => {
    console.log("Finishing block");
    blockStore.$patch((state) => {
      state.scoreParams.open = true;
      state.scoreParams.time = timer.seconds.value;
    });
    timer.stop();
    sendPlausibleEvent("Questões - Bloco finalizado");

    // Update streak
    authStore.$patch((state) => {
      const dayOfTheWeek = new Date().getDay();

      if (
        state.studentStreak.week[dayOfTheWeek] &&
        state.studentStreak.week[dayOfTheWeek].status !== "conquered"
      ) {
        state.studentStreak.streak += 1;
        state.studentStreak.week[dayOfTheWeek].status = "conquered";
      }
    });
  };

  const nextQuestion = () => {
    if (questionNumber.value === questions.value.length - 1) {
      finishBlock();
      return;
    }

    timer.reset();
    timer.start();

    blockStore.$patch({
      questionNumber: questionNumber.value + 1,
    });
  };

  const computeAnswer = async (letter: AnswerLetters) => {
    if (!currentQuestion.value) return;

    blockStore.$patch({
      isComputing: true,
    });

    // Send answer to server
    const submitResult = await blockLoader.sendAnswer(
      currentQuestion.value.id,
      letter,
      timer.seconds.value
    );

    if (!submitResult) {
      blockStore.$patch({
        isComputing: false,
      });
      toast.error(
        "Erro ao computar resposta. Tente novamente. Caso o erro persista, contate o suporte."
      );
      return;
    }

    console.log(
      "Result:",
      submitResult.gradeVariation,
      submitResult.newSubjectScore
    );

    const solution = await blockLoader.getSolution(currentQuestion.value.id);

    sendPlausibleEvent("Questões - Respondida");

    // Add to currentDayStat
    authStore.$patch((state) => {
      state.totalQuestionsToday += 1;
    });

    // Update questions count
    authStore.$patch((state) => {
      const subject = state.subjects.find((sub) => sub.id === subjectId);

      if (subject) {
        subject.questions += 1;
        subject.score = submitResult.newSubjectScore;
      }
    });

    blockStore.$patch((state) => {
      if (submitResult) {
        state.scoreParams.gradeChange += submitResult.gradeVariation;
        state.scoreParams.currentGrade = submitResult.newSubjectScore;
      }
    });

    // If answer is correct
    if (selectedAnswer.value === currentQuestion.value.answer) {
      isCelebrationOpen.value = true;

      // Add stars
      authStore.$patch((state) => {
        const sub = state.subjectsStars.find((sub) => sub.id === subjectId);

        if (sub) {
          sub.stars += 1;
        }
      });

      sendPlausibleEvent("Questões - Respondida corretamente");

      setTimeout(() => {
        answerStatus.value = "correct";
        isCelebrationOpen.value = false;
      }, 1200);
    } else {
      answerStatus.value = "incorrect";
    }

    // Update current solution
    blockStore.$patch({
      currentSolution: solution,
    });

    const isCorrect = currentQuestion.value.answer === letter;

    // Update score
    blockStore.$patch((state) => {
      state.scoreParams.corrects += isCorrect ? 1 : 0;
      state.scoreParams.time += timer.seconds.value;
    });

    timer.stop();

    blockStore.$patch({
      isComputing: false,
    });
  };

  return {
    scoreParams,
    currentQuestion,
    questionNumber,
    stepNumber,
    questions,
    isLoading,
    elapsedTime: timer.formatedTime,
    computeAnswer,
    startNewBlock,
    nextQuestion,
  };
}
