import { toast } from "~/components/toast";
import type { AnswerLetters } from "./useBlockHandler";

export type BlockQuestions = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBlockLoader>["loadNewBlock"]>>
>["questions"];

export default function useBlockLoader(subjectId: string, competenceId?: string) {
  const apiClient = useApi();
  const router = useRouter();
  const questions = ref<BlockQuestions>([]);

  const loadNewBlock = async () => {
    try {
      const res = await apiClient.student.questions.block.$get({
        query: {
          subjectId,
          competenceId,
        }
      })

      if (!res.ok) {
        toast.error("Erro ao carregar questões");
        router.go(-1);
      }

      const questionsResult = await res.json();

      return {
        paywall: false,
        ...questionsResult,
      };
    } catch (e) {
      toast.error(
        "Erro ao carregar questões. Tente novamente. Se o problema persistir contate o suporte."
      );
      router.go(-1);
      return null;
    }
  };

  const getSolution = async (questionId: string) => {
    const res = await apiClient.student.questions.solution[":questionId"].$get({
      param: {
        questionId,
      },
    });

    if (!res.ok) {
      toast.error("Erro ao carregar resolução. Contate o suporte.");
      return;
    }

    const solution = await res.json();

    return solution;
  };

  const sendAnswer = async (
    questionId: string,
    answer: AnswerLetters,
    timeInSeconds: number
  ) => {
    try {
      const res = await apiClient.student.questions.submit.$post({
        json: {
          questionId,
          answer,
          timeInSeconds,
        },
      });

      if (!res.ok) {
        toast.error("Erro ao enviar resposta");
        throw new Error("Erro ao enviar resposta");
      }

      const question = await res.json();

      return question;
    } catch (e) {
      return null;
    }
  };

  return {
    loadNewBlock,
    sendAnswer,
    getSolution,
  };
}
